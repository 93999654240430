.inboxThreadType {
  padding-left: 45px !important;
}
.inboxThreadTypeInner {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}
.inboxStarIcon {
  width: 16px !important;
  height: 16px !important;
  position: absolute;
  margin-left: -24px;
}
.inboxFileIcon {
  width: 24px !important;
  height: 24px !important;
  position: absolute;
  margin-top: -3px;
  margin-left: -30px;
  color: #325127;
}
.paginationSelectRoot {
  padding-right: 10px;
}

.maxWidth100prs {
  max-width: 100% !important;
}

.issueLine {
  cursor: pointer;
}
.issueAvatar {
  position: fixed !important;
  margin-left: -45px;
}
.issueDetails {
  margin: 0 0 20px 0;
  padding: 0;
}
.issueDetails dt {
  font-size: 12px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.54);
  padding: 5px 0 2px 0;
}
.issueDetails dd {
  color: #000;
  padding: 5px 0;
  border-top: rgba(0, 0, 0, 0.08) dashed 1px;
  margin-bottom: 3px;
}

.threadSender {
  font-weight: 500;
}
.threadUnseen td {
  font-weight: 500;
  background: #f9fbe7;
}
.threadUnseen:hover td {
  background: #f0f4c3;
}
.threadAvatar {
  width: 30px !important;
  height: 36px !important;
  position: absolute !important;
  top: 5px;
  left: -20px;
  padding: 5px;
}
.threadChatIcon {
  margin-left: -15px;
}
.threadFileIcon {
  margin-right: 0px;
}

.messagePreview {
  color: #aaa;
}
.messageHeading {
  flex-basis: 63.33%;
  flex-shrink: 0;
  padding-top: 4px !important;
}
.messageDate {
  text-align: right !important;
  font-size: 0.9em !important;
  padding-top: 4px !important;
  justify-content: flex-end !important;
}
.messageAvatar {
  position: absolute;
  margin: -3px 10px 0px -38px;
  width: 28px !important;
  height: 28px !important;
}
.messageIcon {
  width: 16px !important;
  height: 16px !important;
}
.messageDetail {
  white-space: 'pre-wrap';
  display: block !important;
}
.messageHasAttachmentIcon {
  width: 18px !important;
  height: 18px !important;
  position: absolute;
  margin-left: -20px;
}

.expandIssuesContainer {
  position: absolute;
  margin-left: -34px;
}

.expandIssuesIcon {
  cursor: pointer;
  margin-top: -8px;
  font-size: 2.6rem !important;
}

/* SubHeader */
.subHeaderLabel {
  display: block;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.8em;
  text-transform: uppercase;
  line-height: 1.8;
}
.subHeaderStatusIconOpen {
  color: #aed581;
  font-size: 18px !important;
  margin-right: 6px;
  margin-bottom: -4px;
}
.subHeaderStatusIconClosed {
  color: #e57373;
  font-size: 18px !important;
  margin-right: 6px;
  margin-top: -2px;
  margin-bottom: -4px;
}
.subHeaderClaimLink {
  font-size: 16px;
  font-weight: 300;
}

/* attachmentDropZone */
.attachmentDropZone {
  margin: 0px 30px 25px 30px;
  border: 1px dashed #ccc;
  padding: 15px;
  border-radius: 5px;
}
.attachmentDropZone:hover {
  background-color: #f9fbe7;
  border: 1px solid #325127;
}

/* Bubble */
.bubbleWrapLeft,
.bubbleWrapRight {
  position: relative;
  margin-bottom: 30px;
}
.bubbleIconWrap {
  position: absolute;
  margin-top: 5px;
}

.nonBubble {
  position: relative;
  margin-top: 5px;
  padding: 0px 15px;
  display: inline-block;
}

.bubble {
  position: relative;
  background: #efefef;
  border-radius: 0.4em;
  margin-top: 0;
  padding: 15px;
  display: inline-block;
}
.bubble:after {
  content: '';
  position: absolute;
  top: 22px;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  margin-top: -15px;
}
.bubbleFooter {
  color: #999;
  margin: 2px 5px;
  font-size: 0.9em;
}

/* Left Bubble */
.bubbleWrapLeft .bubble,
.bubbleWrapLeft .nonBubble {
  margin-left: 50px;
}
.bubbleWrapLeft .bubble:after {
  left: 0;
  border-left: 0;
  margin-left: -15px;
  border-right-color: #efefef;
}
.bubbleWrapLeft .bubbleFooter {
  text-align: left;
  margin-left: 65px;
}

/* Right Bubble */
.bubbleWrapRight {
  text-align: right;
}

.bubbleWrapRight .bubbleIconWrap {
  right: 0px;
}
.bubbleWrapRight .bubble,
.bubbleWrapRight .nonBubble {
  margin-right: 50px;
  text-align: right;
}
.bubbleWrapRight .bubble:after {
  right: 0;
  border-right: 0;
  margin-right: -15px;
  border-left-color: #efefef;
}
.bubbleWrapRight .bubbleFooter {
  text-align: right;
  margin-right: 65px;
}

.dropzone {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px dashed #ccc;
  border-radius: 15px;
  background-color: #efefef;
  display: block;
}
.dropzoneText {
  display: block;
  padding: 10px;
  text-align: center;
  color: #999;
}
.dropzoneText strong {
  font-weight: 500;
  color: #999;
}

@media (min-width: 601px) {
  .createIssueDrawer > div:nth-child(3) {
    width: 600px;
  }
  .issueDrawer {
    width: 600px;
  }
}
@media (max-width: 600px) {
  .createIssueDrawer > div:nth-child(3) {
    width: 100%;
  }
  .issueDrawer {
    width: 100%;
  }
}
