/* the basics */
html {
  font-size: 14px;
  height: 100%;
  width: 100%;
}
body {
  font-size: 14px;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  height: 100%;
  width: 100%;
}
* {
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}
a {
  color: #325127;
  text-decoration: none;
}
a:not([href]):not([tabindex]) {
  color: rgba(95, 143, 48, 0.26);
  cursor: wait;
}
a:hover,
a:active {
  color: #8fb16f;
  text-decoration: none;
}
label {
  font-weight: 400;
}
strong {
  font-weight: 500;
}
.greenLink {
  color: #73af69;
}

/* misc */
.linethroughRow td {
  text-decoration: line-through;
}
.linethroughExceptLastColumn td {
  text-decoration: line-through;
}
.linethroughExceptLastColumn td:last-child {
  text-decoration: none;
}

.termsPaper {
  padding: '10px';
  max-width: '1000px';
  margin: '60px 1%';
}

.footerLogo {
  width: 120px;
  height: 45px;
  float: left;
  margin: 20px 30px 20px 0px;
}

@media screen and (max-width: 900px) {
  .footerLogo {
    float: none;
    margin-bottom: 10px;
  }
}

@media (max-width: 1199px) {
  .footerLogo {
    margin-left: -25px;
  }
}

@media print {
  .footerLogo {
    display: none !important;
  }
  .no-print {
    display: none !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
